import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Button, Form, Input, Typography } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../../store';
import { moduleName as authModuleName, changeStateAction, forgotPasswordAction } from '../../../../store/ducks/auth';
import { JsonResult } from '../../../../store/ducks/common';
import { RootState } from '../../../../store/reducers';
import { login } from '../../../../utils/inputRules';
import styles from '../index.module.less';

interface IForgotPasswordData {
  email: string;
}

interface IForgotPassword {
  loading: boolean;
  isEmailSent: boolean;
  forgotPassword: (email: string) => Action;
  changeState: (payload: JsonResult) => Action;
}

const ForgotPassword: React.FC<IForgotPassword> = ({ loading, isEmailSent, forgotPassword, changeState }) => {
  useEffect(() => {
    if (!isEmailSent) {
      return;
    }

    changeState({ isEmailSent: false });
  }, []);

  const handleSubmit = (values: IForgotPasswordData) => {
    forgotPassword(values.email);
  };

  return (
    <div className={styles.container}>
      {!isEmailSent ? (
        <>
          <div>
            <Typography.Title className={styles.title}>Forgot your password?</Typography.Title>
            <Typography.Text className={styles.description}>
              Enter your emails and we will send you instructions to reset your password.
            </Typography.Text>
          </div>
          <Form layout="vertical" className={styles.form} onFinish={handleSubmit} labelWrap>
            <Form.Item name="email" label="Email" rules={login}>
              <Input size="large" />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                loading={loading}
                disabled={loading}
                type="primary"
                size="large"
                htmlType="submit"
                className={styles.authButton}
              >
                Send Reset Instructions
              </Button>
            </Form.Item>
          </Form>
          <div className={styles.footer}>
            <Link className={styles.link} to="/auth/sign-in">
              Return to Sign In
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className={styles.iconContainer}>
            <CheckCircleOutlinedIcon className={styles.icon} />
            <Typography.Title className={styles.title}>Email sent successfully</Typography.Title>
          </div>
          <Typography.Text className={styles.description}>Please follow instructions in your email</Typography.Text>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[authModuleName].loading,
  isEmailSent: state[authModuleName].isEmailSent,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  forgotPassword: (email: string) => dispatch(forgotPasswordAction(email)),
  changeState: (payload: JsonResult) => dispatch(changeStateAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
