import { IPlan } from '../types';
import { IVoice } from '../types/entries';

export const CUSTOMER_ID = process.env.REACT_APP_CUSTOMER_ID;

export const PLANS: IPlan[] = [
  {
    id: '8d2dccd5-58b0-4a39-b50f-412a21de6d89',
    name: 'Lite',
    description: 'Basic AI voice features ideal for startup web and phone use',
    price: 50,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '200 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web integration',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: false,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: false,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: false,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: false,
      },
    ],
  },
  {
    id: 'cda5e9a0-4858-4c74-b942-1affee0871a7',
    name: 'Standard',
    description: 'Basic AI voice features ideal for startup web and phone use',
    price: 400,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '1,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: false,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: false,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: false,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: false,
      },
    ],
  },
  {
    id: '8255dd54-314b-400a-ba9f-6ce00e5ea2ed',
    name: 'Professional',
    description: 'Expanded AI integration and features for involving business needs',
    price: 1650,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '5,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: true,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: true,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: false,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: false,
      },
    ],
    isActive: true,
  },
  {
    id: '404a959d-c184-4d52-8023-8960ab83b095',
    name: 'Enterprise',
    description: 'Comprehensive AI solution with full support for large enterprises',
    price: 3200,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '10,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: true,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: true,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: true,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: true,
      },
    ],
  },
];

export const VOICES_LIST: IVoice[] = [
  {
    id: 'EXAVITQu4vr4xnSDxMaL',
    name: 'Sarah',
    gender: 'Female',
    age: 'Middle Aged',
    accent: 'American',
  },
  {
    id: 'rCmVtv8cYU60uhlsOo1M',
    name: 'Ana',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'ZF6FPAbjXT4488VcRRnw',
    name: 'Amelia',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'onwK4e9ZLuTAKqWW03F9',
    name: 'Daniel',
    gender: 'Male',
    age: 'Middle Aged',
    accent: 'British',
  },
  {
    id: 'kmSVBPu7loj4ayNinwWM',
    name: 'Archie',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'Tx7VLgfksXHVnoY6jDGU',
    name: 'Joe',
    gender: 'Male',
    age: 'Middle Aged',
    accent: 'British',
  },
  {
    id: 'vUKrlGoAcbSAtL97NNSZ',
    name: 'Ella',
    gender: 'Female',
    age: 'Young',
    accent: 'British',
  },
  {
    id: 'goy5bVgqqimRihpR9kWL',
    name: 'Eleanor',
    gender: 'Female',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'oU3kUEnMEftGz5TU25Ws',
    name: 'Betty',
    gender: 'Female',
    age: 'Middle',
    accent: 'British',
  },
  {
    id: 'kt7RvYg9kcvIauN97Qwg',
    name: 'Judith',
    gender: 'Female',
    age: 'Middle',
    accent: 'British',
  },
  {
    id: 'vgCPhUoKxN3gZULrzdKv',
    name: 'Addie',
    gender: 'Female',
    age: 'Young',
    accent: 'British',
  },
  {
    id: 'EeJVHStIxAKSIOEbOEtn',
    name: 'Angela',
    gender: 'Female',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'EqG9qtIbcVCwyA4BPfOk',
    name: 'Alexander',
    gender: 'Male',
    age: 'Middle',
    accent: 'British',
  },
  {
    id: 'lRsk18ZW9ClK7lyzHAhS',
    name: 'Justin',
    gender: 'Male',
    age: 'Young',
    accent: 'British',
  },
  {
    id: 'Kt03UBrRy7ar4JKyzFIF',
    name: 'Robert',
    gender: 'Male',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'mHeBvCv4wdnGKEldF90P',
    name: 'Neil',
    gender: 'Male',
    age: 'Young',
    accent: 'British',
  },
  {
    id: 'kV1pWUgbAuL5sgrBbZ0p',
    name: 'Christopher',
    gender: 'Male',
    age: 'Middle',
    accent: 'British',
  },
  {
    id: 'Sb1dPr4UctwAZUxw4wls',
    name: 'Gideon',
    gender: 'Male',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'ZPH1u1uUFhJCEh1yeJXJ',
    name: 'Emily',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'FA6HhUjVbervLw2rNl8M',
    name: 'Sophie',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'B5vjwBxGgp4GLTiUjDxM',
    name: 'Harry',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'ZbrLkeK64iMQ0miuliH4',
    name: 'James',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'aD6riP1btT197c6dACmy',
    name: 'Rebecca',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'L1aJrPa7pLJEyYlh3Ilq',
    name: 'William',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'FiQVBfstqvAXNba9nkRV',
    name: 'Callum',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'DikmR0aoFXAp1A3NcovW',
    name: 'Alex',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: '4qwRZ2Hb5lSLDeQLbnDK',
    name: 'Leo',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'lccV0upjorg83cj9X63o',
    name: 'Edward',
    gender: 'Male',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'KJF2ogTBPpTHWHAqRZrS',
    name: 'Daniel',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: '2mltbVQP21Fq8XgIfRQJ',
    name: 'Alfie',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'rfkTsdZrVWEVhDycUYn9',
    name: 'Holly',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'z0DcTcYlLxVjFO7tr9yB',
    name: 'George',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'mZ8K1MPRiT5wDQaasg3i',
    name: 'Frederick',
    gender: 'Male',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'wo6udizrrtpIxWGp2qJk',
    name: 'Terry',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'lUCNYQh2kqW2wiie85Qk',
    name: 'Grace',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'aTbnroHRGIomiKpqAQR8',
    name: 'Charlotte',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: '8N2ng9i2uiUWqstgmWlH',
    name: 'Bethany',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: '4CrZuIW9am7gYAxgo2Af',
    name: 'Laura',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: '9V6CvwsLXxm7GWktRAj9',
    name: 'Pippa',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: '9tTAUoagEtsVqJ60J8lL',
    name: 'Maria',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
];
