import { UserOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-table';
import { Avatar, Typography } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';

import { pageSize } from '../../../config';
import { Action } from '../../../store';
import { moduleName as authModuleName } from '../../../store/ducks/auth';
import { IParams, moduleName as charactersModuleName, getCharactersAction } from '../../../store/ducks/characters';
import { RootState } from '../../../store/reducers';
import { UUID } from '../../../types';
import { ICharacter, IUser } from '../../../types/entries';
import ContentCard from '../ContentCard';
import Pagination from '../Pagination';
import Table from '../Table';

interface ICharacterPerformance {
  characterUuid?: UUID;
  characters: ICharacter[] | null;
  user: IUser | null;
  total: number;
  getCharacters: (organisationUuid: UUID, params?: IParams, assistantUuid?: UUID) => Action;
  extraColumn?: ProColumns<ICharacter>;
}
const CharacterPerformance: React.FC<ICharacterPerformance> = ({
  characterUuid,
  characters,
  user,
  total,
  getCharacters,
  extraColumn,
}) => {
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (characters !== null && !characters.length) {
      navigate('/characters/create');
    }
  }, [characters]);

  useEffect(() => {
    if (!user?.organisation?.uuid) {
      return;
    }

    getCharacters(user.organisation.uuid, { page, size: pageSize }, characterUuid);
  }, [page, user]);

  const columns: ProColumns<ICharacter>[] = [
    {
      title: '',
      width: 60,
      dataIndex: 'image',
      renderText: (image) => (
        <Avatar className="border-1 border-color-primary" size={60} icon={<UserOutlined />} src={image} />
      ),
    },
    {
      title: '',
      width: 'auto',
      render: (_, row) => (
        <div className="flex-column gap-4">
          <Typography.Text className="fs-16 fw-500 lh-1">{row.name}</Typography.Text>
          <Typography.Text className="fs-12 fw-400 ws-nowrap lh-1">{row.purpose}</Typography.Text>
        </div>
      ),
    },
    {
      title: 'Empty Calls',
      width: 120,
      align: 'center',
      dataIndex: 'emptyCount',
      renderText: (emptyCount) => (
        <Typography.Text className="fs-24 lh-1 fw-500 ws-nowrap">{emptyCount}</Typography.Text>
      ),
    },
    {
      title: 'Calls Handled',
      width: 120,
      align: 'center',
      dataIndex: 'successCount',
      renderText: (successCount) => (
        <Typography.Text className="fs-24 lh-1 fw-500 ws-nowrap">{successCount}</Typography.Text>
      ),
    },
    {
      title: 'Total Calls',
      width: 120,
      align: 'center',
      dataIndex: 'totalCount',
      renderText: (totalCount) => (
        <Typography.Text className="fs-24 lh-1 fw-500 ws-nowrap">{totalCount}</Typography.Text>
      ),
    },
    {
      title: '% Positive',
      width: 100,
      align: 'center',
      dataIndex: 'rating',
      renderText: (rating) => (
        <Typography.Text
          className={clsx('fs-24 lh-1 fw-500 ws-nowrap', {
            'color-green': rating >= 90,
            'color-blue': rating < 90 && rating >= 50,
            'color-red': rating < 50,
          })}
        >
          {rating}%
        </Typography.Text>
      ),
    },
  ];

  if (extraColumn) {
    columns.push(extraColumn);
  }

  return (
    <ContentCard title="Character Performance" align="left">
      <div className="w-full flex-column gap-20">
        <Table<ICharacter>
          dataSource={characters ?? []}
          columns={columns}
          toolBarRender={false}
          search={false}
          pagination={false}
        />
        {!characterUuid && <Pagination page={page} onChange={setPage} total={total} pageSize={pageSize} />}
      </div>
    </ContentCard>
  );
};

const mapStateToProps = (state: RootState) => ({
  characters: state[charactersModuleName].charactersList,
  user: state[authModuleName].user,
  total: state[charactersModuleName].charactersTotal,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCharacters: (organisationUuid: UUID, params?: IParams, assistantUuid?: UUID) =>
    dispatch(getCharactersAction(organisationUuid, params, assistantUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CharacterPerformance);
