export const displayName = 'Neural Voice';
export const name = 'neural-voice';

export const charactersLimit = 1;

export const pageSize = 10;
export const getDataDelay = 2000;
export const imageUploadLimitMb = 10;

export const requestCountLimit = 2;

export const alertDelayError = 10000;

export const alertDelayResendEmail = 119;

export const resizeTriggerDelay = 200;
