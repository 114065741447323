import { ChevronLeftOutlined } from '@mui/icons-material';
import { Button, Form, Input, Typography } from 'antd';
import { isCompanyEmail } from 'company-email-validator';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Action, Dispatch } from 'redux';

import { ISignUp, moduleName as authModuleName, signUpAction } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import { login, password as passwordRule, required } from '../../../../utils/inputRules';
import { Logo } from '../../../Common/Icon';
import styles from '../index.module.less';

interface ISignUpComponent {
  loading: boolean;
  error: Error | null;
  signUp: (data: ISignUp) => Action;
}
const SignUp: React.FC<ISignUpComponent> = ({ loading, signUp }) => {
  const [isDataSet, setIsDataSet] = useState<boolean>(false);

  // TODO Commented for current iteration
  // const handleGoogleSignUp = useGoogleLogin({
  //   onSuccess: (tokenResponse) => {
  //     console.log(tokenResponse);
  //   },
  // });

  const handleFormSubmit = useCallback(
    (values: ISignUp) => {
      if (isDataSet) {
        signUp(values);
      } else {
        setIsDataSet(true);
      }
    },
    [isDataSet],
  );

  const handleBack = useCallback(() => {
    setIsDataSet(false);
  }, []);

  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <Typography.Title className={styles.title}>Sign Up to Neural Voice</Typography.Title>

      {/* TODO Commented for current iteration */}
      {/* <Button size="large" onClick={() =>
       handleGoogleSignUp()} className={styles.authButton} icon={<IconGoogle />}> */}
      {/*  Sign up with Google */}
      {/* </Button> */}
      {/* <div className={styles.divider}> */}
      {/*  <div className={styles.line} /> */}
      {/*  <Typography className={styles.text}>or</Typography> */}
      {/*  <div className={styles.line} /> */}
      {/* </div> */}

      <Form layout="vertical" className={styles.form} onFinish={handleFormSubmit} labelWrap>
        <Form.Item name="first_name" label="First Name" rules={!isDataSet ? required : undefined} noStyle={isDataSet}>
          {!isDataSet && <Input size="large" disabled={loading} />}
        </Form.Item>
        <Form.Item name="last_name" label="Last Name" rules={!isDataSet ? required : undefined} noStyle={isDataSet}>
          {!isDataSet && <Input size="large" disabled={loading} />}
        </Form.Item>
        <Form.Item
          name="email"
          label="Business Email"
          rules={
            !isDataSet
              ? [
                  ...login,
                  () => ({
                    validator(_, value) {
                      if (!value || isCompanyEmail(value)) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('Please enter business Email Address only!'));
                    },
                  }),
                ]
              : undefined
          }
          noStyle={isDataSet}
        >
          {!isDataSet && <Input size="large" disabled={loading} />}
        </Form.Item>
        <Form.Item
          name="organisation_name"
          label="Business Name"
          rules={!isDataSet ? required : undefined}
          noStyle={isDataSet}
        >
          {!isDataSet && <Input size="large" disabled={loading} />}
        </Form.Item>
        <Form.Item name="password" label="Password" rules={isDataSet ? passwordRule : undefined} noStyle={!isDataSet}>
          {isDataSet && <Input.Password size="large" disabled={loading} />}
        </Form.Item>
        <Form.Item
          name="password_confirm"
          label="Password Confirm"
          dependencies={['password']}
          noStyle={!isDataSet}
          rules={
            isDataSet
              ? [
                  ...passwordRule,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('The new password that you entered do not mach!'));
                    },
                  }),
                ]
              : undefined
          }
        >
          {isDataSet && <Input.Password size="large" disabled={loading} />}
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" className={styles.authButton} loading={loading}>
            Next
          </Button>
        </Form.Item>
        {isDataSet && (
          <Form.Item noStyle>
            <Button
              type="text"
              className="m-auto color-primary"
              icon={<ChevronLeftOutlined className="fs-26 color-primary" />}
              onClick={handleBack}
            >
              Back
            </Button>
          </Form.Item>
        )}
      </Form>
      <div className={styles.footer}>
        <Typography className={styles.link}>
          Already have an account?{' '}
          <Link className={styles.signIn} to="/auth/sign-in">
            Sign In
          </Link>
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[authModuleName].loading,
  error: state[authModuleName].error,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  signUp: (data: ISignUp) => dispatch(signUpAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
