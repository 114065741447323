import { Typography } from 'antd';
import clsx from 'clsx';
import React, { CSSProperties } from 'react';

import styles from './index.module.less';

interface ITag {
  label: string;
  type: string;
  style?: CSSProperties;
}

const Tag: React.FC<ITag> = ({ label, type, style }) => (
  <div
    style={style}
    className={clsx(styles.tag, {
      [styles.positive]: type.toLowerCase().includes('positive'),
      [styles.neutral]: type.toLowerCase().includes('neutral'),
      [styles.negative]: type.toLowerCase().includes('negative'),
      [styles.draft]: type.toLowerCase().includes('draft'),
    })}
  >
    <Typography.Text className="fs-12 fw-400 ws-nowrap lh-1 color-inherit">{label}</Typography.Text>
  </div>
);

export default Tag;
