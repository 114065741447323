import { useLayoutEffect, useRef, useState } from 'react';

import { resizeTriggerDelay } from '../config';

export const useWindowSize = () => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    const updateSize = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setSize([window.innerWidth, window.innerHeight]);
      }, resizeTriggerDelay);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};
