import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { UUID } from '../../../../types';
import ContentCard from '../../../Common/ContentCard';
import LatestRecordings from '../../../Common/LatestRecordings';
import PageWrapper from '../../../Common/PageWrapper';

const Records: React.FC = () => {
  const { characterUuid } = useParams();

  return (
    <PageWrapper
      characterUuid={characterUuid as UUID}
      extraColumn={{
        width: 180,
        align: 'center',
        dataIndex: 'id',
        renderText: (id) => (
          <Link to={`/characters/${id}`} className="text-decoration-none">
            View Details
          </Link>
        ),
      }}
    >
      <ContentCard title="Recordings" width="100%">
        <LatestRecordings characterUuid={characterUuid as UUID} />
      </ContentCard>
    </PageWrapper>
  );
};

export default Records;
