export const copyTextToClipboard = (text: string) =>
  'clipboard' in navigator
    ? navigator.clipboard.writeText(text)
    : new Promise(() => {
        document.execCommand('copy', true, text);
      });

export const getCurrencySymbol = (currency: string) =>
  (0)
    .toLocaleString('en-US', {
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      currency,
    })
    .replace(/\d/g, '')
    .trim();

export const prettyTextWithUnderscores = (text: string, join?: string) =>
  text
    ? text
        .split('_')
        .map((word) => word ?? '')
        .map((word, index) => (!index ? `${word[0].toUpperCase()}${word.slice(1, word.length)}` : word))
        .map((word) => (join === undefined || !!join ? `${word[0].toUpperCase()}${word.slice(1, word.length)}` : word))
        .join(join ?? ' ')
    : '';
