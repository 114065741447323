import qs from 'qs';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { alertDelayError, name as appName } from '../../config';
import { IPlan, UUID } from '../../types';
import { IDomain, IPackage, IPeriod } from '../../types/customer';
import { PLANS } from '../constants';
import { Action } from '../index';
import { fetchSaga } from './auth';
import { FetchResponse, JsonResult, cancelableLocationSaga, defaultResponseProcessing } from './common';
import { UPDATE_STATE as UPDATE_LOCAL_STATE, openNotificationAction } from './local';

/**
 * Constants
 * */
export const moduleName = 'customer';
const prefix = `${appName}/${moduleName}`;

export const CUSTOMER_START = `${prefix}/CUSTOMER_START`;
export const CUSTOMER_SUCCESS = `${prefix}/CUSTOMER_SUCCESS`;
export const CUSTOMER_ERROR = `${prefix}/CUSTOMER_ERROR`;
export const CUSTOMER_ERROR_RESET = `${prefix}/CUSTOMER_ERROR_RESET`;

export const GET_CUSTOMER_PERIOD = `${prefix}/GET_CUSTOMER_PERIOD`;
export const GET_CUSTOMER_INVOICES = `${prefix}/GET_CUSTOMER_INVOICES`;
export const GET_PLANS = `${prefix}/GET_PLANS`;
export const GET_PAYMENT_LINK = `${prefix}/GET_PAYMENT_LINK`;
export const SET_PAYMENT_LINK = `${prefix}/SET_PAYMENT_LINK`;

export const DOMAINS_START = `${prefix}/DOMAINS_START`;
export const DOMAINS_SUCCESS = `${prefix}/DOMAINS_SUCCESS`;
export const GET_DOMAINS = `${prefix}/GET_DOMAINS`;
export const ADD_DOMAIN = `${prefix}/ADD_DOMAIN`;
export const DELETE_DOMAIN = `${prefix}/DELETE_DOMAIN`;

/**
 * Reducer
 * */
export interface State {
  loading: boolean;
  domainsLoading: boolean;
  domains: IDomain[] | null;
  error: Error | null;
  period: IPeriod | null;
  package: IPackage | null;
  invoices: JsonResult[] | null;
  total: number;
  plans: IPlan[] | null;
  paymentLink: string | null;
}

const localState: State = {
  loading: false,
  domainsLoading: false,
  error: null,
  period: null,
  package: null,
  invoices: null,
  domains: null,
  total: 0,
  plans: PLANS,
  paymentLink: null,
};

export default function reducer(state = localState, action: Action = { type: 'undefined' }): State {
  const { type, payload } = action;

  switch (type) {
    case CUSTOMER_START:
      return { ...state, loading: true, error: null };
    case CUSTOMER_SUCCESS:
      return { ...state, loading: false, ...payload };
    case CUSTOMER_ERROR:
      return { ...state, loading: false, error: payload };
    case CUSTOMER_ERROR_RESET:
      return { ...state, loading: false, error: null };
    case SET_PAYMENT_LINK:
      return { ...state, ...payload };

    case DOMAINS_START:
      return { ...state, domainsLoading: true };
    case DOMAINS_SUCCESS:
      return { ...state, domainsLoading: false, ...payload };

    default:
      return state;
  }
}

/**
 * Interfaces
 * */

export interface IGetDomains {
  organisationUuid: UUID;
}

export interface IAddDomain {
  organisationUuid: UUID;
  domain: string;
}

export interface IDeleteDomain {
  organisationUuid: UUID;
  domainUuid: UUID;
}

/**
 * Action Creators
 * */

export interface ICustomerParams {
  customerId: string;
  periodId?: number;
}

export interface IGetPaymentLink {
  organisationUuid: UUID;
  packageUuid: UUID;
}

export interface ICustomerPayload {
  payload: ICustomerParams;
}

export interface IGetInvoicesParams {
  limit?: number;
  starting_after?: string;
  ending_before?: string;
}

export const getCustomerPeriodAction = (organisationUuid: UUID): Action => ({
  type: GET_CUSTOMER_PERIOD,
  payload: { organisationUuid },
});

export const getCustomerInvoices = (payload: IGetInvoicesParams): Action => ({
  type: GET_CUSTOMER_INVOICES,
  payload,
});

export const getPlans = (): Action => ({
  type: GET_PLANS,
});

export const getPaymentLink = (payload: IGetPaymentLink): Action => ({
  type: GET_PAYMENT_LINK,
  payload,
});

export const setPaymentLink = (paymentLink: string | null): Action => ({
  type: SET_PAYMENT_LINK,
  payload: { paymentLink },
});

export const getDomainsAction = (payload: IGetDomains): Action => ({
  type: GET_DOMAINS,
  payload,
});

export const addDomainAction = (payload: IAddDomain): Action => ({
  type: ADD_DOMAIN,
  payload,
});

export const deleteDomainAction = (payload: IDeleteDomain): Action => ({
  type: DELETE_DOMAIN,
  payload,
});

/**
 * Sagas
 */
export function* getCustomerPeriodSaga({
  payload: { organisationUuid },
}: {
  payload: { organisationUuid: UUID };
}): Generator {
  yield put({
    type: CUSTOMER_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_BILLING_API_URL}/organisation/${organisationUuid}/current-period`,
  )) as FetchResponse;

  yield defaultResponseProcessing(response, CUSTOMER_SUCCESS, CUSTOMER_ERROR, false, (period) => ({ period }));
}

export function* getCustomerInvoicesSaga({ payload }: { payload: IGetInvoicesParams }): Generator {
  yield put({
    type: CUSTOMER_START,
  });

  const params = qs.stringify(payload);

  const response = (yield call(
    fetchSaga,
    `https://api.stripe.com/v1/invoices?expand[0]=total_count&${params}`,
    {},
    { token: process.env.REACT_APP_STRIPE_SECRET_ID ?? '' },
  )) as FetchResponse;

  yield defaultResponseProcessing(response, CUSTOMER_SUCCESS, CUSTOMER_ERROR, false, (data) => ({
    invoices: data.data,
    total: data.total_count,
  }));
}

export function* getPlansSaga(): Generator {
  yield put({
    type: CUSTOMER_START,
  });

  yield put({
    type: CUSTOMER_SUCCESS,
    payload: { plans: PLANS },
  });
}

export function* getPaymentLinkSaga({
  payload: { organisationUuid, packageUuid },
}: {
  payload: IGetPaymentLink;
}): Generator {
  yield put({
    type: CUSTOMER_START,
  });

  const response = (yield call(
    fetchSaga,
    // eslint-disable-next-line max-len
    `${process.env.REACT_APP_BILLING_API_URL}/organisation/${organisationUuid}/subscribe/package/${packageUuid}/generate-checkout`,
    {
      method: 'POST',
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(response, CUSTOMER_SUCCESS, CUSTOMER_ERROR, false, (data) => ({
    paymentLink: data,
  }));
}

export function* getDomainsSaga({ payload: { organisationUuid } }: { payload: IGetDomains }): Generator {
  yield put({
    type: DOMAINS_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_BILLING_API_URL}/domains/get_domains?organisation_uuid=${organisationUuid}`,
  )) as FetchResponse;

  yield defaultResponseProcessing(response, DOMAINS_SUCCESS, CUSTOMER_ERROR, false, ({ domains }) => ({
    domains,
  }));
}

export function* addDomainSaga({ payload: { organisationUuid, domain } }: { payload: IAddDomain }): Generator {
  yield put({
    type: DOMAINS_START,
  });

  const response = (yield call(fetchSaga, `${process.env.REACT_APP_BILLING_API_URL}/domains/add_domain`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ organisationUuid, domain }),
  })) as FetchResponse;

  let message;

  yield defaultResponseProcessing(response, DOMAINS_SUCCESS, CUSTOMER_ERROR, false, (data) => {
    message = data.message;
  });

  if (message) {
    yield put({
      type: UPDATE_LOCAL_STATE,
      payload: {
        notification: { message },
      },
    });
    yield call(openNotificationAction, { message });
  }

  yield put({
    type: GET_DOMAINS,
    payload: {
      organisationUuid,
    },
  });
}

export function* deleteDomainSaga({
  payload: { organisationUuid, domainUuid },
}: {
  payload: IDeleteDomain;
}): Generator {
  yield put({
    type: DOMAINS_START,
  });

  const response = (yield call(fetchSaga, `${process.env.REACT_APP_BILLING_API_URL}/domains/remove_domain`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ organisationUuid, domainUuid }),
  })) as FetchResponse;

  yield defaultResponseProcessing(response, DOMAINS_SUCCESS, CUSTOMER_ERROR, false);

  yield put({
    type: GET_DOMAINS,
    payload: {
      organisationUuid,
    },
  });
}

export function* saga(): Generator {
  yield takeLatest(
    GET_CUSTOMER_PERIOD,
    cancelableLocationSaga.bind(null, getCustomerPeriodSaga, CUSTOMER_ERROR, false),
  );
  yield takeLatest(
    GET_CUSTOMER_INVOICES,
    cancelableLocationSaga.bind(null, getCustomerInvoicesSaga, CUSTOMER_ERROR, false),
  );
  yield takeLatest(GET_PLANS, cancelableLocationSaga.bind(null, getPlansSaga, CUSTOMER_ERROR, false));
  yield takeLatest(GET_PAYMENT_LINK, cancelableLocationSaga.bind(null, getPaymentLinkSaga, CUSTOMER_ERROR, false));

  yield takeLatest(GET_DOMAINS, cancelableLocationSaga.bind(null, getDomainsSaga, CUSTOMER_ERROR, false));
  yield takeLatest(ADD_DOMAIN, cancelableLocationSaga.bind(null, addDomainSaga, CUSTOMER_ERROR, false));
  yield takeLatest(DELETE_DOMAIN, cancelableLocationSaga.bind(null, deleteDomainSaga, CUSTOMER_ERROR, false));

  yield takeLatest(CUSTOMER_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: CUSTOMER_ERROR_RESET,
    });
  });
}
