import { ProColumns } from '@ant-design/pro-table';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { Button, ModalFuncProps, Typography } from 'antd';
import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../../../../store';
import { openConfirmModalAction } from '../../../../../../store/ducks/local';
import { IDeleteRecord, deleteRecord as deleteRecordAction } from '../../../../../../store/ducks/records';
import { IRecord } from '../../../../../../types/entries';
import AudioWave from '../../../../../Common/AudioWave';
import Table from '../../../../../Common/Table';
import Tag from '../../../../../Common/Tag';

interface IRecordInfo {
  record?: IRecord | null;
  deleteRecord: (payload: IDeleteRecord) => Action;
  openConfirm: (params: ModalFuncProps) => Action;
}

const RecordInfo: React.FC<IRecordInfo> = ({ record, openConfirm, deleteRecord }) => {
  const { characterId, recordId } = useParams();

  const handleDelete = useCallback(() => {
    if (!characterId || !recordId) {
      return;
    }

    deleteRecord({ id: recordId, characterId });
  }, [characterId, recordId]);

  const handleDeleteClick = useCallback(() => {
    openConfirm({
      title: 'Delete',
      content: 'Are you sure you want to delete this record?',
      okText: 'Delete',
      okType: 'danger',
      onOk: handleDelete,
    });
  }, [handleDelete]);

  const columns: ProColumns<IRecord>[] = [
    {
      title: 'Date',
      width: 100,
      render: (_, row) => (
        <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1">
          {moment(row.date).format('DD MMM, YYYY')}
        </Typography.Text>
      ),
    },
    {
      title: 'Time',
      width: 100,
      render: (_, row) => (
        <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1">
          {moment(row.date).format('HH:mm')}
        </Typography.Text>
      ),
    },
    {
      title: 'Playback',
      width: 'auto',
      dataIndex: 'audio',
      render: (_, row) => <AudioWave id={row.id} source={row.audio} width="100%" height={40} />,
    },
    {
      title: 'Sentiment',
      width: 100,
      render: (_, row) => <Tag label={row.sentiment} type={row.sentiment} />,
    },
    {
      width: 40,
      render: () => (
        <Button className="btn-danger icon-button" type="text" onClick={handleDeleteClick}>
          <DeleteOutlineOutlined className="fs-18" />
        </Button>
      ),
    },
  ];

  return (
    <Table<IRecord>
      dataSource={record ? [record] : []}
      columns={columns}
      toolBarRender={false}
      search={false}
      pagination={false}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openConfirm: (params: ModalFuncProps) => dispatch(openConfirmModalAction(params)),
  deleteRecord: (params: IDeleteRecord) => dispatch(deleteRecordAction(params)),
});

export default connect(null, mapDispatchToProps)(RecordInfo);
