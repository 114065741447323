import clsx from 'clsx';
import React from 'react';

import styles from './index.module.less';

interface ILoading {
  size?: 'small' | 'medium' | 'large';
  fixed?: boolean;
  absolute?: boolean;
  visible: boolean;
  className?: string;
}

const Loading: React.FC<ILoading> = ({ size, absolute, fixed, visible, className }): JSX.Element | null =>
  visible ? (
    <div
      className={clsx(styles.loading, className, {
        [styles.absolute]: absolute,
        [styles.fixed]: fixed,
      })}
    >
      <span className={clsx(styles.loader, styles[size ?? 'large'])} />
    </div>
  ) : null;

export default Loading;
