import React from 'react';

import { IconNotFoundContent } from '../Icon';
import styles from './index.module.less';

interface NotFoundContent {
  message?: string;
}

const NotFoundContent: React.FC<NotFoundContent> = ({ message }) => (
  <div className={styles.wrapper}>
    <div>
      <IconNotFoundContent />
    </div>
    <div className={styles.message}>{message ?? 'No Data.'}</div>
  </div>
);

export default NotFoundContent;
