import { Button, Segmented, Tabs } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../../../store';
import { moduleName as charactersModuleName, getCharacterAction } from '../../../../../store/ducks/characters';
import { RootState } from '../../../../../store/reducers';
import { UUID } from '../../../../../types';
import { ICharacterDetails, ICharactersStats } from '../../../../../types/entries';
import OverallSentiment from '../../../../Common/Analytics/OverallSentiment';
import ContentCard from '../../../../Common/ContentCard';
import LatestRecordings from '../../../../Common/LatestRecordings';
import Loading from '../../../../Common/Loading';
import PageWrapper from '../../../../Common/PageWrapper';
import PromptTab from './PromptTab';
import VoiceTab from './VoiceTab';

declare global {
  interface Window {
    getNeuralVoicePopupSettings: () => void;
  }
}

interface ICharacterDetailsComponent {
  loading: boolean;
  characterLoading: boolean;
  character: ICharacterDetails | null;
  stats: ICharactersStats | null;
  getCharacter: (assistantUuid: UUID) => Action;
}

const CharacterDetails: React.FC<ICharacterDetailsComponent> = ({
  loading,
  characterLoading,
  character,
  stats,
  getCharacter,
}) => {
  const { characterUuid } = useParams();
  const navigate = useNavigate();
  const [alignValue, setAlignValue] = useState<SegmentedValue>('Content');

  useEffect(() => {
    if (!characterUuid) {
      return;
    }

    getCharacter(characterUuid as UUID);
  }, [characterUuid]);

  useEffect(() => {
    if (!character?.activation_id) {
      return;
    }

    if (typeof window.getNeuralVoicePopupSettings === 'function') {
      window.getNeuralVoicePopupSettings();
    }
  }, [character]);

  useEffect(() => {
    if (loading || character || character === null) {
      return;
    }

    navigate('/not-found', { replace: true });
  }, [loading, character]);

  const totalCalls = Object.values(stats?.sentimental ?? {}).reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0,
  );

  const tabs = [
    {
      label: 'Content',
      component: <PromptTab />,
    },
    {
      label: 'Voice',
      component: <VoiceTab />,
    },
    // TODO commented for this iteration
    // {
    //   label: 'Other Settings',
    //   component: <Script character={character} />,
    // },
  ];

  if (totalCalls) {
    tabs.splice(2, 0, {
      label: 'Sentiment',
      component: <OverallSentiment sentiment={stats?.sentimental} />,
    });
  }

  const handleChangeAlignValue = (value: SegmentedValue) => {
    setAlignValue(value);
  };

  return (
    <PageWrapper
      characterUuid={characterUuid as UUID}
      loading={loading || characterLoading}
      extraColumn={{
        width: 180,
        align: 'center',
        renderText: () =>
          character?.activation_id ? (
            <Button id={character.activation_id} type="primary">
              Try Now
            </Button>
          ) : (
            <div />
          ),
      }}
    >
      <ContentCard width="100%">
        <div className="w-full">
          <Loading
            visible={loading}
            absolute
            className="background-transparent backdrop-filter-blur-10 border-radius-16"
          />

          <Tabs
            renderTabBar={() => (
              <Segmented
                defaultValue="Content"
                block
                onChange={handleChangeAlignValue}
                options={tabs.map((item) => item.label)}
              />
            )}
            activeKey={alignValue as string}
            items={tabs.map((tab) => ({
              label: tab.label,
              key: tab.label,
              children: tab.component,
            }))}
          />
        </div>
      </ContentCard>
      <LatestRecordings title="Recent Calls" characterUuid={characterUuid} />
    </PageWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[charactersModuleName].loading,
  characterLoading: state[charactersModuleName].characterLoading,
  character: state[charactersModuleName].character,
  stats: state[charactersModuleName].stats,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCharacter: (assistantUuid: UUID) => dispatch(getCharacterAction(assistantUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CharacterDetails);
