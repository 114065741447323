import { useEffect, useState } from 'react';

export const useThemeDetector = (): [boolean, () => void] => {
  const getCurrentTheme = () =>
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : window.matchMedia('(prefers-color-scheme: dark)').matches;

  const [isDark, setIsDark] = useState<boolean>(getCurrentTheme());

  const getThemeName = (isDarkTheme: boolean) => (isDarkTheme ? 'dark' : 'light');

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');

    darkThemeMq.onchange = (e) => {
      const storedTheme = localStorage.getItem('theme');

      if (!storedTheme) {
        setIsDark(e.matches);
      }
    };
  }, []);

  useEffect(() => {
    const { body } = document;
    const bodyTheme = body.getAttribute('data-theme');
    const theme = getThemeName(isDark);

    if (!bodyTheme || bodyTheme === theme) {
      return;
    }

    body.setAttribute('data-theme', theme);
  }, [isDark]);

  const toggleTheme = () => {
    setIsDark((prev) => {
      localStorage.setItem('theme', getThemeName(!prev));

      return !prev;
    });
  };

  return [isDark, toggleTheme];
};
