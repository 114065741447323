import { CheckCircle, Close, Error as ErrorIcon, Info, Warning } from '@mui/icons-material';
import { Modal, ModalFuncProps, notification as notificationApi } from 'antd';
import { ArgsProps } from 'antd/es/notification';
import { IconType } from 'antd/lib/notification';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Action } from './store';
import { clearConfirmModalAction, clearNotificationAction, moduleName as localModuleName } from './store/ducks/local';
import { RootState } from './store/reducers';

const ICONS: { [key in IconType]: ReactElement | undefined } = {
  info: <Info />,
  success: <CheckCircle />,
  error: <ErrorIcon />,
  warning: <Warning />,
};

interface IMain {
  children: React.ReactNode;
  notification: ArgsProps | null;
  confirmModal: ModalFuncProps | null;
  clearNotification: () => Action;
  clearConfirmModal: () => Action;
}

const Main: React.FC<IMain> = ({ children, notification, confirmModal, clearConfirmModal, clearNotification }) => {
  const [api, notificationsContextHolder] = notificationApi.useNotification();
  const [modal, confirmContextHolder] = Modal.useModal();

  useEffect(() => {
    if (!notification) {
      return;
    }

    const { type = 'success', placement = 'topRight', icon, duration = 3 } = notification;

    api[type]({
      ...notification,
      placement,
      icon: icon ?? ICONS[type],
      duration,
      closeIcon: <Close />,
    });

    clearNotification();
  }, [notification]);

  useEffect(() => {
    if (!confirmModal) {
      return;
    }

    const { okText = 'Confirm', cancelText = 'Cancel' } = confirmModal;

    modal.confirm({
      icon: null,
      centered: true,
      ...confirmModal,
      okText,
      cancelText,
    });

    clearConfirmModal();
  }, [confirmModal]);

  return (
    <>
      {notificationsContextHolder}
      {confirmContextHolder}
      {children}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  notification: state[localModuleName].notification,
  confirmModal: state[localModuleName].confirmModal,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearNotification: () => dispatch(clearNotificationAction()),
  clearConfirmModal: () => dispatch(clearConfirmModalAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
