import { Button, Typography } from 'antd';
import { ArgsProps } from 'antd/es/notification';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Action } from '../../../../store';
import { moduleName as customerModuleName } from '../../../../store/ducks/customer';
import { openNotificationAction } from '../../../../store/ducks/local';
import { RootState } from '../../../../store/reducers';
import { IDomain } from '../../../../types/customer';
import { copyTextToClipboard } from '../../../../utils/text';
import ContentCard from '../../../Common/ContentCard';
import styles from '../index.module.less';

interface IScriptTab {
  domains: IDomain[] | null;
  openNotification: (params: ArgsProps) => Action;
}

const Script: React.FC<IScriptTab> = ({ domains, openNotification }) => {
  const code = `<script src="${process.env.REACT_APP_NV_SCRIPT_URL}"></script>`;

  const handleCopyScript = useCallback(() => {
    if (!domains?.length) {
      openNotification({ type: 'warning', message: 'Do not forget to enter a domain in settings!' });
    }

    copyTextToClipboard(code).then(() => {
      openNotification({ message: 'The code is copied to the clipboard' });
    });
  }, [domains, code]);

  return (
    <ContentCard>
      <div className="flex-column gap-16">
        <Typography.Title level={3} className="color-primary fs-20 m-0">
          Embed script
        </Typography.Title>
        <Typography className="fs-16 lh-1 color-primary m-0 fw-400">
          To use your character on your website or app, please paste the below script immediately before the closing
          &#x3c;/body&#x3e; tag on your site
        </Typography>
        <Typography className={styles.code}>{code}</Typography>
        <div className="flex-row flex-justify-end">
          <Button onClick={handleCopyScript}>Copy Code</Button>
        </div>
      </div>
    </ContentCard>
  );
};

const mapStateToProps = (state: RootState) => ({
  domains: state[customerModuleName].domains,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openNotification: (params: ArgsProps) => dispatch(openNotificationAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Script);
