import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../../store';
import { moduleName as authModuleName, checkIsUserPaid as checkIsUserPaidAction } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';

interface IConfirmation {
  authorized: boolean;
  checkIsUserPaid: () => Action;
}
const Confirmation: React.FC<IConfirmation> = ({ authorized, checkIsUserPaid }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!authorized) {
      checkIsUserPaid();
    }
  }, [authorized]);

  const backHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 20,
      }}
    >
      <CheckCircleOutlined className="color-success" style={{ fontSize: 52 }} />
      <Typography.Title level={3} className="color-primary">
        Thanks for your payment
      </Typography.Title>
      <Button type="primary" onClick={backHome}>
        Back Home
      </Button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  authorized: state[authModuleName].authorized,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  checkIsUserPaid: () => dispatch(checkIsUserPaidAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
