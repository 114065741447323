import { Button } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../../store';
import { moduleName as authModuleName } from '../../../../store/ducks/auth';
import {
  ICheckIfCharacterCanBeCreatedAction,
  ICreateCharacterParams,
  IParams,
  moduleName as charactersModuleName,
  checkIfCharacterCanBeCreatedAction,
  createCharacterAction,
  getCharactersAction,
} from '../../../../store/ducks/characters';
import { RootState } from '../../../../store/reducers';
import { UUID } from '../../../../types';
import { ICharacter, IUser } from '../../../../types/entries';
import Loading from '../../../Common/Loading';
import ModalCommon from '../../../Common/ModalCommon';
import NotFoundContent from '../../../Common/NotFoundContent';
import Pagination from '../../../Common/Pagination';
import CreateCharacterForm from './CreateCharacterForm';
import CreateCharacterLimits from './CreateCharacterLimits';
import CreateCharacterSuccess from './CreateCharacterSuccess';
import Tile from './Tile';

interface ICharacters {
  loading: boolean;
  characters: ICharacter[] | null;
  user: IUser | null;
  total: number;
  isCharacterCanBeCreated: boolean;
  getCharacters: (organisationUuid: UUID, params?: IParams, assistantUuid?: UUID) => Action;
  createCharacter: (params: ICreateCharacterParams) => Action;
  checkIfCharacterCanBeCreated: (params: ICheckIfCharacterCanBeCreatedAction) => Action;
}

const pageSize = 4;

const Characters: React.FC<ICharacters> = ({
  loading,
  characters,
  user,
  total,
  isCharacterCanBeCreated,
  getCharacters,
  createCharacter,
  checkIfCharacterCanBeCreated,
}) => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [page, setPage] = useState<number>(1);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [isLimitsModalOpen, setIsLimitsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (characters !== null && !characters.length) {
      navigate('/characters/create');
    }
  }, [characters]);

  const handleModalOpen = useCallback(() => {
    if (!isCharacterCanBeCreated) {
      setIsLimitsModalOpen(true);
    } else {
      setIsCreateModalOpen(true);
    }
  }, [isCharacterCanBeCreated]);

  const handleCreateModalClose = useCallback(() => {
    setIsCreateModalOpen(false);
    form.resetFields();
  }, [form]);

  const handleSuccessModalClose = useCallback(() => {
    setIsSuccessModalOpen(false);
  }, []);

  const handleLimitsModalClose = useCallback(() => {
    setIsLimitsModalOpen(false);
  }, []);

  const handleCharactersLoad = useCallback(() => {
    if (!user?.organisation?.uuid) {
      return;
    }

    checkIfCharacterCanBeCreated({ organisationUuid: user.organisation.uuid });
    getCharacters(user.organisation.uuid, { page, size: pageSize });
  }, [user, page, pageSize]);

  const characterCreateCallback = useCallback(() => {
    handleCreateModalClose();
    setIsSuccessModalOpen(true);
    handleCharactersLoad();
  }, [handleCreateModalClose, handleCharactersLoad]);

  const handleCharacterSave = useCallback(() => {
    form
      .validateFields()
      .then((values: ICreateCharacterParams) => {
        createCharacter({ ...values, callback: characterCreateCallback });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Validation Failed:', error);
      });
  }, [form, characterCreateCallback]);

  useEffect(() => {
    handleCharactersLoad();
  }, [page, user]);

  return (
    <div className="flex-column gap-40">
      <Loading visible={loading} fixed className="background-transparent backdrop-filter-blur-10 border-radius-16" />
      {isCreateModalOpen && (
        <ModalCommon
          title="Character Setup"
          isVisible={isCreateModalOpen}
          onOk={handleCharacterSave}
          onClose={handleCreateModalClose}
          loading={loading}
        >
          <CreateCharacterForm form={form} />
        </ModalCommon>
      )}
      {isSuccessModalOpen && (
        <ModalCommon isVisible={isSuccessModalOpen} onClose={handleSuccessModalClose} hideFooter>
          <CreateCharacterSuccess />
        </ModalCommon>
      )}
      {isLimitsModalOpen && (
        <ModalCommon isVisible={isLimitsModalOpen} onClose={handleLimitsModalClose} hideFooter>
          <CreateCharacterLimits />
        </ModalCommon>
      )}
      <Button type="primary" onClick={handleModalOpen}>
        + New Character
      </Button>
      {characters && !!characters.length && (
        <div className="grid grid-cols-autofill gap-24">
          {characters.map((character) => (
            <Tile key={character.id} character={character} />
          ))}
        </div>
      )}
      {!characters?.length && !loading && <NotFoundContent />}

      <Pagination page={page} onChange={setPage} total={total} pageSize={pageSize} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[charactersModuleName].loading,
  characters: state[charactersModuleName].charactersList,
  user: state[authModuleName].user,
  total: state[charactersModuleName].charactersTotal,
  isCharacterCanBeCreated: state[charactersModuleName].isCharacterCanBeCreated,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCharacters: (organisationUuid: UUID, params?: IParams, assistantUuid?: UUID) =>
    dispatch(getCharactersAction(organisationUuid, params, assistantUuid)),
  createCharacter: (params: ICreateCharacterParams) => dispatch(createCharacterAction(params)),
  checkIfCharacterCanBeCreated: (params: ICheckIfCharacterCanBeCreatedAction) =>
    dispatch(checkIfCharacterCanBeCreatedAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Characters);
